import * as React from "react";
import { Flex, Box } from "rebass";
import { Icon, Input } from "antd";
import styled from "styled-components";
// @ts-ignore
import Fade from "react-reveal/Fade";

import { Section, FocusText, Title, Para, PrimaryButton } from "../basic";
import PatternBackground from "../PatternBackground";
import FollowIcons from "../FollowIcons";

interface Props {
  focusText?: string;
  title: string;
  subtitle?: string;
}

const ContactSection: React.FC<Props> = ({ focusText, title, subtitle }) => {
  return (
    <PatternBackground patternType={5}>
      <Section>
        <Flex
          flexDirection="column"
          justifyContent="center"
          style={{ height: "100%" }}
        >
          <Fade bottom>
            <FocusText>{focusText}</FocusText>
          </Fade>
          <Fade bottom>
            <Title>{title}</Title>
          </Fade>
          <Fade bottom delay={200}>
            <Para>{subtitle}</Para>
          </Fade>
          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            action="/success/"
          >
            <Flex flexDirection={["column", "column", "row"]} mt={5}>
              <Box width={[1, 1, 1 / 3]}>
                <Fade bottom delay={600}>
                  <StyledLink
                    target="_blank"
                    href="tel:+918882868919"
                    rel="noopener noreferrer"
                  >
                    <Flex alignItems="center" mb={4}>
                      <Icon
                        type="phone"
                        style={{ fontSize: 20, marginRight: 15 }}
                      />
                      +918882868919
                    </Flex>
                  </StyledLink>
                </Fade>
                <Fade bottom delay={600}>
                  <StyledLink
                    target="_blank"
                    href="mailto:imchetanyadav@gmail.com"
                    rel="noopener noreferrer"
                  >
                    <Flex alignItems="center" mb={4}>
                      <Icon
                        type="mail"
                        style={{ fontSize: 20, marginRight: 15 }}
                      />
                      imchetanyadav@gmail.com
                    </Flex>
                  </StyledLink>
                </Fade>
                <Fade bottom delay={600}>
                  <Flex alignItems="center" mb={4}>
                    <Icon
                      type="environment"
                      style={{ fontSize: 20, marginRight: 15 }}
                    />
                    Gurugram, Haryana, India
                  </Flex>
                </Fade>
                <Fade bottom delay={400}>
                  <div style={{ marginTop: 20 }}>
                    <FollowIcons />
                  </div>
                </Fade>
              </Box>
              <Flex width={[1, 1, 2 / 3]} flexWrap="wrap">
                <Box width={[1, 1, 1 / 2]} p={2}>
                  <input type="hidden" name="form-name" value="Contact Form" />
                  <Fade bottom delay={600}>
                    <InputField placeholder="Name" name="Name" required />
                  </Fade>
                </Box>
                <Box width={[1, 1, 1 / 2]} p={2}>
                  <Fade bottom delay={600}>
                    <InputField placeholder="Company" name="Company" required />
                  </Fade>
                </Box>
                <Box width={[1, 1, 1 / 2]} p={2}>
                  <Fade bottom delay={600}>
                    <InputField placeholder="Phone" name="Phone" required />
                  </Fade>
                </Box>
                <Box width={[1, 1, 1 / 2]} p={2}>
                  <Fade bottom delay={600}>
                    <InputField
                      placeholder="Email"
                      name="Email"
                      type="email"
                      required
                    />
                  </Fade>
                </Box>
                <Box width={1} p={3}>
                  <Fade bottom delay={400}>
                    <InputTextArea
                      placeholder="Message"
                      name="Message"
                      required
                      rows={6}
                    />
                  </Fade>
                  <Fade bottom delay={400}>
                    <PrimaryButton type="submit">Submit</PrimaryButton>
                  </Fade>
                </Box>
              </Flex>
            </Flex>
          </form>
        </Flex>
      </Section>
    </PatternBackground>
  );
};

export default ContactSection;

const InputField = styled(Input)`
  &.ant-input {
    background-color: transparent;
    color: ${props => props.theme.colors.foreground};
    border: 1px solid ${props => props.theme.colors.grey};
    padding: 20px 0 20px 20px;
    border-radius: 50px;
    box-shadow: none;
  }
`;
const InputTextArea = styled(Input.TextArea)`
  &.ant-input {
    background-color: transparent;
    color: ${props => props.theme.colors.foreground};
    border: 1px solid ${props => props.theme.colors.grey};
    padding: 20px 0 20px 20px;
    border-radius: 10px;
    box-shadow: none;
  }
`;
const StyledLink = styled("a")`
  color: ${props => props.theme.colors.foreground};
`;
