import * as React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import ContactSection from "../components/index/ContactSection";

const pageSchemaContent = {
  name: "Contact",
  "@type": "ContactPage",
  breadcrumb: {
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://imchetanyadav.com/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "Contact",
        item: "https://imchetanyadav.com/contact",
      },
    ],
  },
};

export default (props: any) => {
  return (
    <Layout>
      <SEO
        pageTitle="Contact"
        pageDescription="You seem like a super cool person. Don’t be shy; send me a message or give me a follow and we’ll be friends in no time."
        pageSchemaContent={pageSchemaContent}
        pageKeywords={[`contact`]}
      />
      <ContactSection
        focusText="CONTACT"
        title="Get in touch"
        subtitle="You seem like a super cool person. Don’t be shy; send me a message or give me a follow and we’ll be friends in no time."
      />
    </Layout>
  );
};
