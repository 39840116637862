import { Icon } from "antd";
import { Flex } from "rebass";
import * as React from "react";
import { Tooltip } from "antd";
// @ts-ignore
import Zoom from "react-reveal/Zoom";

import { IconContainer } from "./basic";

const socials = [
  {
    title: "/imchetanyadav",
    icon: "linkedin",
    href: "https://linkedin.com/in/imchetanyadav",
  },
  {
    title: "/imchetanyadav",
    icon: "github",
    href: "https://github.com/imchetanyadav/",
  },
  {
    title: "/imchetanyadav",
    icon: "dribbble",
    href: "https://dribbble.com/imchetanyadav",
  },
  {
    title: "/im_chetanyadav",
    icon: "twitter",
    href: "https://twitter.com/im_chetanyadav",
  },
  {
    title: "@imchetanyadav",
    icon: "medium",
    href: "https://medium.com/@imchetanyadav",
  },
  // { title: "/imchetanyadav", icon: "instagram", href: "" },
  // { title: "/imchetanyadav", icon: "skype", href: "" },
];

export default function FollowIcons() {
  return (
    <Flex justifyContent="flex-start">
      {socials.map(s => (
        <a key={s.icon} href={s.href} target="_blank" rel="noopener noreferrer">
          <Zoom delay={200}>
            <Tooltip title={s.title} placement="bottom">
              <IconContainer className={s.icon}>
                <Icon type={s.icon} style={{ fontSize: 35 }} />
              </IconContainer>
            </Tooltip>
          </Zoom>
        </a>
      ))}
    </Flex>
  );
}
